import { BookingCartItemInput, PlannerTripFragment, Trip } from '../generated/graphql';

export interface Booking {
    type?: 'flexible-random' | 'flexible-weekends' | 'coming-month' | 'static';
    amountOfNights?: number;
    flexibleMonth?: string;

    arrivalDate?: string;
    departureDate?: string;
    amountAdults?: number;
    amountChildren?: number;
    amountPets?: number;
    amountBabies?: number;
    amountYouths?: number;
    /**
     * When a trip is set, it is a valid booking
     */
    trip?: PlannerTripFragment;
}

export type RequiredBooking = Omit<Required<Booking>, 'trip'> & { trip?: Trip };

// keys from Booking that get transferred to the cart on creation
export const bookingCartKeys: Array<keyof Booking & keyof BookingCartItemInput> = [
    'amountAdults',
    'amountBabies',
    'amountChildren',
    'amountPets',
    'amountYouths',
    'arrivalDate',
    'departureDate',
];
