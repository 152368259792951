import Stepper from '@oberoninternal/travelbase-ds/components/form/Stepper';
import Modal from '@oberoninternal/travelbase-ds/components/layout/Modal';
import ModalHeader from '@oberoninternal/travelbase-ds/components/layout/ModalHeader';
import Body from '@oberoninternal/travelbase-ds/components/primitive/Body';
import useSesame from '@oberoninternal/travelbase-ds/hooks/useSesame';
import { Box, Flex } from '@rebass/grid';
import isEqual from 'lodash.isequal';
import React, { FC, memo, ReactNode, useEffect, useMemo, useState } from 'react';
import { FormattedMessage, IntlShape, useIntl } from 'react-intl';
import styled from 'styled-components';
import { GroupType } from '../constants/brandConfig';
import { CLASS_SEARCH_BOX_TRIP_GROUP_FIELD_ROUND_BUTTON } from '../constants/customizationClassNames';
import travelGroupMessages from '../constants/travelGroupMessages';
import { useTenantContext } from '../context/TenantContext';
import { Booking } from '../entities/Booking';
import usePrevious from '../hooks/usePrevious';
import getTripGroupText from '../utils/getTripGroupText';
import Dropdown, { DropdownDescription, DropdownProps } from './designsystem/Dropdown';
import Persons from './svg/Persons.svg';

export interface Props extends Pick<DropdownProps, 'width' | 'className'> {
    booking: Booking;
    setBooking: (booking: Booking) => void;
    loading: boolean;
    dropDownBorderRadius?: number;
    maxOccupancy?: number;
    minOccupancy?: number;
    petsAllowed?: number;
    numberOfPersons?: number;
    variant?: 'modal' | 'dropdown' | 'inputs';
    onToggle?: () => void;
    open?: boolean;
    surfaceElementVariant?: 'searchbar' | 'input';
}

const createGetGroupTypeProps = (intl: IntlShape) => (type: GroupType) => {
    const { label, name, description } = travelGroupMessages[type];
    return {
        name,
        label: intl.formatMessage(label),
        description: description && intl.formatMessage(description),
    };
};

const TripGroupInput: FC<React.PropsWithChildren<Props>> = memo(
    ({
        loading,
        booking,
        setBooking,
        dropDownBorderRadius,
        maxOccupancy,
        minOccupancy,
        petsAllowed,
        variant = 'dropdown',
        surfaceElementVariant = 'input',
        ...props
    }) => {
        const sesame = useSesame(false, { closeOnClickOutside: true, closeOnEscape: true });
        const onToggle = props.onToggle ?? sesame.onToggle;
        const open = props.open ?? sesame.open;

        const intl = useIntl();
        const getGroupTypeProps = createGetGroupTypeProps(intl);
        const { brandConfig } = useTenantContext();

        const [localBooking, setLocalBooking] = useState<Booking>(booking);
        const prevOpen = usePrevious(open);
        const prevBooking = usePrevious(booking);

        const numberOfPersons =
            (localBooking.amountAdults ?? 0) + (localBooking.amountYouths ?? 0) + (localBooking.amountChildren ?? 0);

        const getMaximum = (tripGroupPersons = 0) => {
            if (maxOccupancy && numberOfPersons) {
                return maxOccupancy - numberOfPersons + tripGroupPersons;
            }

            return 120;
        };

        const getMinimum = (tripGroupPersons = 0, defaultMin = 0) => {
            if (numberOfPersons === 1) {
                return 1;
            }
            if (numberOfPersons === minOccupancy) {
                return tripGroupPersons;
            }
            return defaultMin;
        };

        useEffect(() => {
            if (!isEqual(prevBooking, booking)) {
                setLocalBooking(booking);
            }
        }, [booking, prevBooking]);

        useEffect(() => {
            if (variant === 'modal' || variant === 'dropdown') {
                if (prevOpen && !open && !isEqual(localBooking, booking)) {
                    setBooking(localBooking);
                }
                if (!prevOpen && open && !isEqual(localBooking, booking)) {
                    setLocalBooking(booking);
                }
            }
            if (variant === 'inputs') {
                if (!isEqual(localBooking, booking)) {
                    setBooking(localBooking);
                }
            }
        }, [booking, localBooking, open, prevOpen, setBooking, variant]);

        const common = useMemo(
            () => ({ loading, setBooking: setLocalBooking, booking: localBooking, editable: true }),
            [loading, localBooking]
        );

        const renderInputFields = () =>
            brandConfig.groupTypes.map(type => {
                const rowProps = { ...common, ...getGroupTypeProps(type), onToggle, open };
                switch (type) {
                    case 'adults':
                        return (
                            <Row
                                minimum={getMinimum(localBooking.amountAdults)}
                                maximum={getMaximum(localBooking.amountAdults)}
                                key={type}
                                dataCy="tripGroupAdults"
                                {...rowProps}
                            />
                        );
                    case 'babies':
                        return <Row key={type} dataCy="tripGroupBabies" {...rowProps} />;
                    case 'children':
                        return (
                            <Row
                                minimum={getMinimum(localBooking.amountChildren)}
                                maximum={getMaximum(localBooking.amountChildren)}
                                key={type}
                                dataCy="tripGroupChildren"
                                {...rowProps}
                            />
                        );
                    case 'pets':
                        return (
                            <Row
                                maximum={petsAllowed}
                                petsAllowed={petsAllowed}
                                description={petsAllowed === 0 && <FormattedMessage defaultMessage="Niet toegestaan" />}
                                key={type}
                                name={rowProps.name}
                                label={rowProps.label}
                                booking={rowProps.booking}
                                setBooking={rowProps.setBooking}
                                loading={rowProps.loading}
                                dataCy="tripGroupPets"
                            />
                        );
                    case 'youth':
                        return (
                            <Row
                                minimum={getMinimum(localBooking.amountYouths)}
                                maximum={getMaximum(localBooking.amountYouths)}
                                key={type}
                                dataCy="tripGroupYouth"
                                {...rowProps}
                            />
                        );
                    default:
                        throw new Error('Invalid grouptype defined in brandConfig.');
                }
            });

        const guestsText = getTripGroupText(intl, localBooking);

        return variant === 'dropdown' ? (
            <Dropdown
                ref={sesame.ref}
                width={props.width}
                className={props.className}
                title={guestsText}
                surfaceElement={
                    surfaceElementVariant === 'searchbar' ? (
                        <>
                            <Flex flexWrap="wrap">
                                <Box width={1}>
                                    <StyledBody variant="tiny">
                                        <FormattedMessage defaultMessage="Reisgezelschap" />
                                    </StyledBody>
                                </Box>
                                <Box width={1}>
                                    <Box>
                                        <StyledDropdownDescription variant="small" isPlaceholder={!guestsText}>
                                            {guestsText || <FormattedMessage defaultMessage="Reisgezelschap" />}
                                        </StyledDropdownDescription>
                                    </Box>
                                </Box>
                            </Flex>
                        </>
                    ) : (
                        <>
                            <StyledPersons className="gt-s" style={{ marginRight: '1rem', marginTop: '-3px' }} />
                            <StyledDropdownDescription isPlaceholder={!guestsText}>
                                {guestsText || <FormattedMessage defaultMessage="Reisgezelschap" />}
                            </StyledDropdownDescription>
                        </>
                    )
                }
                borderRadius={dropDownBorderRadius}
                open={open}
                onToggle={onToggle}
                dataCy="tripGroupInput"
                scrollIntoView
                modifiers={{ flip: { enabled: false } }}
            >
                {open && (
                    <Box p={4} width="32rem" style={{ maxWidth: '100vw' }}>
                        {renderInputFields()}
                    </Box>
                )}
            </Dropdown>
        ) : variant === 'modal' ? (
            <>
                <Dropdown
                    width={props.width}
                    className={props.className}
                    title={guestsText}
                    surfaceElement={
                        <>
                            <StyledPersons className="gt-s" style={{ marginRight: '1rem', marginTop: '-3px' }} />
                            <StyledDropdownDescription isPlaceholder={!guestsText}>
                                {guestsText || <FormattedMessage defaultMessage="Reisgezelschap" />}
                            </StyledDropdownDescription>
                        </>
                    }
                    borderRadius={dropDownBorderRadius}
                    open={open}
                    onToggle={onToggle}
                    dataCy="tripGroupInput"
                    scrollIntoView
                    modifiers={{ flip: { enabled: false } }}
                />
                <Modal variant="small" open={open} onClose={onToggle}>
                    <ModalHeader>
                        <FormattedMessage defaultMessage="Reisgezelschap" />
                    </ModalHeader>

                    <Box p={4}>{renderInputFields()}</Box>
                </Modal>
            </>
        ) : (
            <>{renderInputFields()}</>
        );
    }
);

interface RowProps extends Props {
    label: ReactNode;
    description?: ReactNode;
    minimum?: number;
    maximum?: number;
    name: 'amountPets' | 'amountAdults' | 'amountChildren' | 'amountBabies' | 'amountYouths';
    petsAllowed?: number;
    dataCy?: string;
    editable?: boolean;
}

const Row = ({
    label,
    description,
    minimum = 0,
    maximum = 10,
    name,
    loading,
    booking,
    setBooking,
    petsAllowed,
    editable,
    dataCy,
}: RowProps) => {
    const setValue = (val: number) => {
        setBooking({ ...booking, [name]: val });
    };

    return (
        <Flex justifyContent="space-between" alignItems="center" data-cy={dataCy}>
            <Box>
                <Body style={{ textTransform: 'capitalize', fontWeight: 400 }}>{label}</Body>
                <Description variant="small">{description}</Description>
            </Box>
            <Stepper
                editable={!!editable}
                size="medium"
                disabled={loading || (name === 'amountPets' && petsAllowed === 0)}
                variant="outline"
                minimum={minimum}
                maximum={maximum}
                value={booking[name] ?? 0}
                setValue={value => {
                    if (typeof value === 'number') {
                        setValue(value);
                    }
                }}
                nullable={false}
                buttonClassName={CLASS_SEARCH_BOX_TRIP_GROUP_FIELD_ROUND_BUTTON}
            />
        </Flex>
    );
};
const StyledBody = styled(Body)`
    color: ${({ theme }) => theme.colors.neutral['60']};
    font-weight: 300 !important;
    text-align: start;
    line-height: 1.15;
`;
const Description = styled(Body)``;
const StyledPersons = styled(Persons)`
    color: ${({ theme }) => theme.colors.neutral['40']};
    width: 2rem;
`;

const StyledDropdownDescription = styled(DropdownDescription)<{ isPlaceholder: boolean }>`
    ${({ isPlaceholder, theme }) => isPlaceholder && `color: ${theme.colors.neutral['60']}`};
`;

export default TripGroupInput;
