import { ComponentType, SVGAttributes } from 'react';
import styled from 'styled-components';
import Caret from '../svg/Caret.svg';

const StyledCaret = styled(Caret)`
    width: 1.2rem;
    @media screen and (min-width: ${({ theme }) => theme.mediaQueries.l}) {
        width: 1.4rem;
    }
    width: 1.4rem;
` as ComponentType<React.PropsWithChildren<SVGAttributes<SVGElement>>>;

export default StyledCaret;
