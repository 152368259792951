import Period from '@oberoninternal/travelbase-ds/entities/Period';
import validInterval from '@oberoninternal/travelbase-ds/utils/validInterval';
import differenceInCalendarDays from 'date-fns/differenceInCalendarDays';
import isSameDay from 'date-fns/isSameDay';
import addDays from 'date-fns/addDays';
import parseToDateString from '../constants/parseToDateString';
import { PlannerTripFragment, Trip, UnitAndTripSearchParamsInput } from '../generated/graphql';
import { Booking, bookingCartKeys } from '../entities/Booking';
import { DecodedValueMap } from 'use-query-params';
import searchParams from '../constants/searchParams';
import pick from 'lodash.pick';
import isWithinInterval from 'date-fns/isWithinInterval';
import removeUndefinedEntries from './removeUndefinedEntries';
import { BrandConfig } from '../constants/brandConfig';
import { format, startOfMonth } from 'date-fns';

export type TripShape = Pick<Trip, 'date' | 'duration' | 'price'>;

const byDuration = <T extends TripShape>(a: T, b: T): number => a.duration - b.duration;

const getDayTrips = <T extends TripShape>(day: Date | number, trips: T[]) =>
    trips.filter(({ date }) => isSameDay(new Date(date), day)).sort(byDuration);

const convertToDateStrings = (period?: Period) => {
    if (!validInterval(period)) {
        return { departureDate: undefined, arrivalDate: undefined };
    }
    return { departureDate: parseToDateString(period.end), arrivalDate: parseToDateString(period.start) };
};

const getDateStringsFromTrip = <T extends TripShape>(trip: T) => ({
    arrivalDate: parseToDateString(new Date(trip.date)),
    departureDate: parseToDateString(addDays(new Date(trip.date), trip.duration)),
});

const filterTripsWithInterval = <T extends TripShape>(trips: T[], interval: Interval, exclusive = false): T[] =>
    trips.filter(trip => {
        const within = isWithinInterval(new Date(trip.date), interval);
        return exclusive ? !within : within;
    });

const getTripsFromPeriod = <T extends TripShape>(period: Interval, trips: T[], skipSort?: boolean): T[] => {
    const potentialDayTrips = getDayTrips(period.start, trips);

    const filteredTrips = potentialDayTrips.filter(
        ({ duration }) => differenceInCalendarDays(period.end, period.start) === duration
    );

    if (skipSort) {
        return filteredTrips;
    }

    return filteredTrips.sort((a, b) => a.price - b.price);
};

const getTripDuration = (arrivalDate: string, departureDate: string): number =>
    differenceInCalendarDays(new Date(departureDate), new Date(arrivalDate));

const convertToPeriod = (arrivalDate: string | undefined, departureDate: string | undefined): Period => {
    if (!arrivalDate || !departureDate) {
        return undefined;
    }
    return {
        start: new Date(arrivalDate),
        end: new Date(departureDate),
    };
};

const validBooking = <T extends Booking>(booking: T): booking is Required<T> => {
    const { arrivalDate, departureDate, trip } = booking ?? {};
    return !!(arrivalDate && departureDate && trip);
};

type RequiredBooking = Omit<Required<Booking>, 'trip'> & { trip?: PlannerTripFragment };

const defaultBookingValues: RequiredBooking = {
    type: 'static',
    flexibleMonth: format(startOfMonth(new Date()), 'MM-yyyy'),
    amountOfNights: 1,
    amountAdults: 2,
    amountBabies: 0,
    amountChildren: 0,
    amountPets: 0,
    amountYouths: 0,
    arrivalDate: '',
    departureDate: '',
};

/**
 * In case certain booking values aren't set they'll be overwritten with the `defaultBookingValues` above
 */
const withDefaultBookingValues = <T extends Booking>(booking: T, brandConfig?: BrandConfig): RequiredBooking & T => {
    const defaults = { ...defaultBookingValues };
    if (brandConfig?.searchBoxGroup?.defaultOccupancy) {
        defaults.amountAdults = brandConfig.searchBoxGroup.defaultOccupancy;
    }
    return {
        ...defaults,
        ...removeUndefinedEntries(booking),
    };
};

const pickBookingFromParams = (
    params: Partial<DecodedValueMap<typeof searchParams>>
): Omit<RequiredBooking, 'trip' | 'flexibleMonth' | 'amountOfNights' | 'type'> =>
    pick(withDefaultBookingValues(params as Booking), bookingCartKeys);

type ConvertBookingToParamsInput = {
    <
        T extends Omit<Booking, 'trip' | 'arrivalDate' | 'departureDate'> & {
            arrivalDate: string;
            departureDate: string;
        },
        E extends Partial<UnitAndTripSearchParamsInput>
    >(
        input: T,
        extensions?: E
    ): UnitAndTripSearchParamsInput;
    <T extends Omit<Booking, 'trip'>, E extends Partial<UnitAndTripSearchParamsInput>>(
        input: T,
        extensions?: E
    ): UnitAndTripSearchParamsInput | null;
};
const convertBookingToParamsInput: ConvertBookingToParamsInput = <
    T extends Omit<Booking, 'trip'>,
    // TODO: UnitAndTripSearchParamsInput = deprecated type! might be removed from backend soon.
    // Needs to be refactored to QuerySearchRentalUnitsArgs | QuerySearchTripsArgs
    E extends Partial<UnitAndTripSearchParamsInput>
>(
    input: T,
    extensions?: E
) => {
    const { amountAdults, amountBabies, amountChildren, amountPets, amountYouths, arrivalDate, departureDate } =
        withDefaultBookingValues(input);

    const inputParams: UnitAndTripSearchParamsInput = {
        pets: amountPets,
        persons: amountAdults + amountChildren + amountYouths,
        babies: amountBabies,
        date: arrivalDate,
        duration: getTripDuration(arrivalDate, departureDate),
        ...extensions,
    };

    return inputParams;
};

export {
    getDayTrips,
    getTripsFromPeriod,
    getTripDuration,
    convertToPeriod,
    convertToDateStrings,
    validBooking,
    getDateStringsFromTrip,
    withDefaultBookingValues,
    convertBookingToParamsInput,
    pickBookingFromParams,
    filterTripsWithInterval,
};
