import { useRef, useEffect, RefObject } from 'react';

const defaultOptions: ScrollIntoViewOptions = { behavior: 'smooth', block: 'nearest' };

const useScrollIntoView = (
    ref: RefObject<HTMLElement>,
    scrollTrigger = true,
    scrollBackTrigger = false,
    options = defaultOptions
) => {
    const scrollIntoViewOptions = { ...defaultOptions, ...options };
    const currentTop = useRef(0);
    useEffect(() => {
        if (scrollTrigger) {
            currentTop.current = window.pageYOffset;
            ref.current?.scrollIntoView(scrollIntoViewOptions);
        }

        if (scrollBackTrigger) {
            window.scrollTo({ behavior: scrollIntoViewOptions.behavior, top: currentTop.current });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [scrollBackTrigger, scrollTrigger]);
};

export default useScrollIntoView;
