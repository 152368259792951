import Body from '@oberoninternal/travelbase-ds/components/primitive/Body';
import createBorderCss from '@oberoninternal/travelbase-ds/utils/createBorderCss';
import { Box, BoxProps } from '@rebass/grid';
import React, { FC, forwardRef, ReactNode, Ref, RefObject, useEffect, useRef, useState } from 'react';
import { ManagerProps, PopperChildrenProps, PopperProps, ReferenceProps } from 'react-popper';
import styled, { css } from 'styled-components';
import StyledCaret from './StyledCaret';
import useScrollIntoView from '../../hooks/useScrollIntoView';
import dynamic from 'next/dynamic';
import { getAssignmentColor } from '@oberoninternal/travelbase-ds/constants/theme';

const Manager = dynamic<ManagerProps>(() => import('react-popper').then(mod => mod.Manager));
const Reference = dynamic<ReferenceProps>(() => import('react-popper').then(mod => mod.Reference));
const Popper = dynamic<PopperProps>(() => import('react-popper').then(mod => mod.Popper), { ssr: false });

export interface DropdownProps extends BoxProps {
    surfaceElement: ReactNode;
    open: boolean;
    onToggle: () => void;
    ref?: Ref<HTMLDivElement>;
    initialFocusRef?: RefObject<HTMLElement>;
    borderRadius?: number;
    dataCy?: string;
    scrollIntoView?: boolean;
    modifiers?: PopperProps['modifiers'];
    popupPlacement?: PopperProps['placement'];
}

const Dropdown: FC<React.PropsWithChildren<DropdownProps>> = forwardRef(
    (
        {
            as,
            children,
            surfaceElement,
            initialFocusRef,
            open,
            dataCy,
            onToggle,
            borderRadius,
            scrollIntoView,
            modifiers,
            popupPlacement,
            ...props
        },
        containerRef
    ) => {
        const [hasFocus, setHasFocus] = useState(false);
        useEffect(() => {
            if (open && !hasFocus) {
                if (initialFocusRef?.current) {
                    initialFocusRef.current.focus();
                }
            }
            if (!open) {
                setHasFocus(false);
            }
        }, [open, hasFocus, initialFocusRef]);

        const contentRef = useRef<HTMLDivElement>(null);
        useScrollIntoView(contentRef, scrollIntoView && open, false, { block: 'nearest' });

        const updateRef = useRef<PopperChildrenProps['scheduleUpdate'] | null>(null);
        useEffect(() => {
            updateRef.current?.();
        }, [open]);

        return (
            <Container {...props} ref={containerRef} data-cy={dataCy} borderRadius={borderRadius}>
                <Manager>
                    <Reference>
                        {({ ref }) => (
                            <Button
                                ref={ref}
                                onClick={onToggle}
                                aria-expanded={open}
                                as={as ?? 'button'}
                                open={open}
                                type="button"
                            >
                                {surfaceElement}
                                <StyledDropdownIcon />
                            </Button>
                        )}
                    </Reference>
                    <Popper
                        modifiers={{
                            offset: { offset: '0, 10' },
                            ...modifiers,
                        }}
                        placement={popupPlacement ?? 'bottom-start'}
                    >
                        {({ ref, style, placement, scheduleUpdate }) => {
                            updateRef.current = scheduleUpdate;
                            return (
                                <ContentWrapper
                                    ref={ref}
                                    style={style}
                                    data-placement={placement}
                                    placement={placement}
                                    open={open}
                                >
                                    <Content open={open} tabIndex={0} ref={contentRef}>
                                        {children}
                                    </Content>
                                </ContentWrapper>
                            );
                        }}
                    </Popper>
                </Manager>
            </Container>
        );
    }
);

export default Dropdown;

const ContentWrapper = styled(Box)<{ placement: PopperProps['placement']; open: boolean }>`
    z-index: ${({ theme }) => theme.zIndices.dropdown};
`;

const Container = styled(Box)<{ borderRadius?: number }>`
    position: relative;
    z-index: 3;
    --surfaceHeight: 4.8rem;
    height: var(--surfaceHeight);
    --borderRadius: ${({ theme, borderRadius }) => borderRadius ?? theme.radius.textInput};
    svg {
        flex-shrink: 0;
    }
`;

export const DropdownDescription = styled(Body)`
    text-align: start;
    display: -webkit-box;
    -webkit-line-clamp: 1;
    text-overflow: ellipsis;
    -webkit-box-orient: vertical;
    overflow: hidden;
`;

const StyledDropdownIcon = styled(StyledCaret)`
    margin-left: auto;
`;

const Button = styled(Box)<{ open: boolean }>`
    background: ${({ theme }) => theme.colors.neutral['0']};
    --border-color: ${({ theme }) => theme.colors.neutral['20']};
    ${createBorderCss('1px')}
    height: var(--surfaceHeight);
    padding: 0 ${({ theme }) => theme.spacing['40_Standard']};
    display: flex;
    align-items: center;
    outline: none;
    width: 100%;
    position: absolute;
    left: 0;
    top: 0;
    color: ${({ theme }) => theme.colors.neutral['80']};
    z-index: 3;
    border-radius: var(--borderRadius);

    ${StyledDropdownIcon} {
        color: ${({ theme }) => theme.colors.neutral['40']};
    }

    ${({ open }) =>
        open &&
        css`
            --border-color: ${({ theme }) => getAssignmentColor(theme, theme.colorAssignments.input)};
            box-shadow: 0 0 0 1px var(--border-color), inset 0 0 0 0.5px var(--border-color);
            ${StyledDropdownIcon} {
                color: ${({ theme }) => theme.colors.neutral['90']};
            }
        `};

    :hover {
        --border-color: ${({ theme, open }) => (open ? theme.colors.neutral['90'] : theme.colors.neutral['40'])};
        box-shadow: 0 0 0 1px var(--border-color), inset 0 0 0 0.5px var(--border-color);
    }
    :focus {
        border-color: transparent;
        --border-color: ${({ theme }) => getAssignmentColor(theme, theme.colorAssignments.input)};
        color: ${({ theme }) => theme.colors.neutral['90']};
    }
    transition: box-shadow 0.15s ease-in-out, color 0.3s ease-in;
`;

const Content = styled(Box)<{ open: boolean }>`
    @media screen and (max-width: ${({ theme }) => theme.mediaQueriesValues.xs}px) {
        min-width: 80vw;
    }
    background: ${({ theme }) => theme.colors.neutral['0']};
    outline: none;
    border-radius: ${({ theme }) => theme.radius.dropdown};
    top: var(--surfaceHeight);

    :focus {
        ::before {
            --border-color: grey;
            ${createBorderCss('1px')};
        }
    }

    box-shadow: 0 0 40px 0 rgba(16, 36, 48, 0.1);

    ${({ open }) =>
        !open &&
        css`
            display: none;
        `};
`;
