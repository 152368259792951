const removeFromObjWhen = <T extends object>(values: T, ...omitters: Array<(val: unknown) => boolean>): T =>
    Object.entries(values).reduce<T>(
        (acc, [key, value]) => ({
            ...acc,
            ...(omitters.every(shouldOmit => !shouldOmit(value)) && { [key]: value }),
        }),
        {} as T
    );

export default removeFromObjWhen;
