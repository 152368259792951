import { defineMessage, MessageDescriptor } from 'react-intl';
import { GroupType } from './brandConfig';

const travelGroupMessages: Record<
    GroupType,
    {
        name: 'amountPets' | 'amountAdults' | 'amountChildren' | 'amountBabies' | 'amountYouths';
        label: MessageDescriptor;
        description?: MessageDescriptor;
    }
> = {
    adults: {
        name: 'amountAdults',
        label: defineMessage({ defaultMessage: 'Volwassenen' }),
        description: defineMessage({ defaultMessage: 'v.a. 18 jaar' }),
    },
    babies: {
        name: 'amountBabies',
        label: defineMessage({ defaultMessage: "Baby's" }),
        description: defineMessage({ defaultMessage: '0 t/m 2 jaar' }),
    },
    children: {
        name: 'amountChildren',
        label: defineMessage({ defaultMessage: 'Kinderen' }),
        description: defineMessage({ defaultMessage: '3 t/m 17 jaar' }),
    },
    pets: {
        name: 'amountPets',
        label: defineMessage({ defaultMessage: 'Huisdieren' }),
    },
    youth: {
        name: 'amountYouths',
        label: defineMessage({ defaultMessage: 'Jongeren' }),
        description: defineMessage({ defaultMessage: '13 t/m 20 jaar' }),
    },
};

export default travelGroupMessages;
